/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AdSlotPlatform = "APP" | "SITE" | "%future added value";
export type AdSlotSspProvider = "ADSTIR" | "ADX" | "BIDSWITCH_GENIEE" | "BIDSWITCH_GMO" | "BIDSWITCH_MICROAD" | "BIDSWITCH_SCALEOUT" | "BIDSWITCH_THEMEDIAGRID" | "BIDSWITCH_UNRULYX" | "BIDSWITCH_YIELDONE" | "FLUCT" | "GENIEE" | "GMO" | "INMOBI" | "OPENX" | "PROFITX" | "SUPERSHIP" | "VRIZE" | "VRIZEAD" | "%future added value";
export type Os = "ALL" | "ANDROID" | "IOS" | "UNKNOWN" | "%future added value";
export type CreateAdSlotInput = {
    clientMutationId?: string | null;
    name?: string | null;
    domain: string;
    tagId?: string | null;
    width?: number | null;
    height?: number | null;
    bundle?: string | null;
    note?: string | null;
    platform?: AdSlotPlatform | null;
    os?: Array<Os> | null;
    sspProvider: AdSlotSspProvider;
    adxBillingId?: string | null;
    targetWinRate: number;
};
export type CreateAdSlotMutationVariables = {
    connections: Array<string>;
    input: CreateAdSlotInput;
};
export type CreateAdSlotMutationResponse = {
    readonly createAdSlot: {
        readonly adSlotEdge: {
            readonly node: {
                readonly id: string;
                readonly name: string | null;
                readonly domain: string;
                readonly tagId: string | null;
                readonly sspProvider: AdSlotSspProvider;
                readonly width: number | null;
                readonly height: number | null;
                readonly bundle: string | null;
                readonly platform: AdSlotPlatform | null;
                readonly adxBillingId: string | null;
                readonly targetWinRate: number;
                readonly placements: {
                    readonly totalCount: number;
                };
                readonly bannerFormat: string | null;
                readonly deletedAt: string | null;
                readonly note: string | null;
                readonly " $fragmentRefs": FragmentRefs<"AdSlotEditForm_adSlot">;
            } | null;
        } | null;
    } | null;
};
export type CreateAdSlotMutation = {
    readonly response: CreateAdSlotMutationResponse;
    readonly variables: CreateAdSlotMutationVariables;
};



/*
mutation CreateAdSlotMutation(
  $input: CreateAdSlotInput!
) {
  createAdSlot(input: $input) {
    adSlotEdge {
      node {
        id
        name
        domain
        tagId
        sspProvider
        width
        height
        bundle
        platform
        adxBillingId
        targetWinRate
        placements {
          totalCount
        }
        bannerFormat
        deletedAt
        note
        ...AdSlotEditForm_adSlot
      }
    }
  }
}

fragment AdSlotEditForm_adSlot on AdSlot {
  id
  name
  domain
  tagId
  bundle
  note
  width
  height
  sspProvider
  platform
  os
  adxBillingId
  targetWinRate
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "connections"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "domain",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tagId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sspProvider",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "width",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "height",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bundle",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "platform",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "adxBillingId",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "targetWinRate",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "PlacementConnection",
  "kind": "LinkedField",
  "name": "placements",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bannerFormat",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedAt",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "note",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateAdSlotMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateAdSlotPayload",
        "kind": "LinkedField",
        "name": "createAdSlot",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AdSlotEdge",
            "kind": "LinkedField",
            "name": "adSlotEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AdSlot",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "AdSlotEditForm_adSlot"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateAdSlotMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateAdSlotPayload",
        "kind": "LinkedField",
        "name": "createAdSlot",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AdSlotEdge",
            "kind": "LinkedField",
            "name": "adSlotEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AdSlot",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "os",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendEdge",
            "key": "",
            "kind": "LinkedHandle",
            "name": "adSlotEdge",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7b3ffd306bd987b24e1597eabb940bb5",
    "id": null,
    "metadata": {},
    "name": "CreateAdSlotMutation",
    "operationKind": "mutation",
    "text": "mutation CreateAdSlotMutation(\n  $input: CreateAdSlotInput!\n) {\n  createAdSlot(input: $input) {\n    adSlotEdge {\n      node {\n        id\n        name\n        domain\n        tagId\n        sspProvider\n        width\n        height\n        bundle\n        platform\n        adxBillingId\n        targetWinRate\n        placements {\n          totalCount\n        }\n        bannerFormat\n        deletedAt\n        note\n        ...AdSlotEditForm_adSlot\n      }\n    }\n  }\n}\n\nfragment AdSlotEditForm_adSlot on AdSlot {\n  id\n  name\n  domain\n  tagId\n  bundle\n  note\n  width\n  height\n  sspProvider\n  platform\n  os\n  adxBillingId\n  targetWinRate\n}\n"
  }
};
})();
(node as any).hash = '95b94a9ac3d719c2e2ff281ecd7c3570';
export default node;
