import { Grid } from "@material-ui/core";
import {
  FormikMultipleSelectField,
  FormikNumberField,
  FormikSelectField,
  FormikTextField,
} from "@vrize/vrizead-use";
import { FormikProps } from "formik";
import * as React from "react";
import * as yup from "yup";

import { DialogForm } from "./DialogForm";

export type FormValues = {
  name: string | null;
  domain: string;
  tagId: string;
  bundle: string | null;
  note: string | null;
  width: number | null;
  height: number | null;
  sspProvider:
    | "ADSTIR"
    | "ADX"
    | "VRIZEAD"
    | "PROFITX"
    | "GENIEE"
    | "FLUCT"
    | "GMO"
    | "OPENX"
    | "BIDSWITCH_GMO"
    | "SUPERSHIP"
    | "INMOBI"
    | "BIDSWITCH_GENIEE"
    | "BIDSWITCH_SCALEOUT"
    | "BIDSWITCH_MICROAD";
  platform: "SITE" | "APP" | null;
  os: ["IOS" | "ANDROID"] | null;
  adxBillingId: string | null;
  targetWinRate: number;
};

type Props = FormikProps<FormValues>;

export const schema = yup.object({
  name: yup.string().required(),
  domain: yup.string().required(),
  tagId: yup.string().required(),
  bundle: yup.string().nullable(),
  note: yup.string().nullable(),
  width: yup.number().nullable(),
  height: yup.number().nullable(),
  sspProvider: yup
    .string()
    .oneOf([
      "ADSTIR",
      "ADX",
      "VRIZEAD",
      "PROFITX",
      "GENIEE",
      "FLUCT",
      "GMO",
      "OPENX",
      "SUPERSHIP",
      "INMOBI",
      "BIDSWITCH_GMO",
      "BIDSWITCH_GENIEE",
      "BIDSWITCH_SCALEOUT",
      "BIDSWITCH_MICROAD",
      "BIDSWITCH_UNRULYX",
    ])
    .required(),
  platform: yup.string().nullable().oneOf(["SITE", "APP", null]),
  category: yup
    .array()
    .of(yup.string().oneOf(["IOS", "ANDROID"]))
    .nullable(),
  adxBillingId: yup.string().nullable(),
  targetWinRate: yup.number().required(),
});

const sspProviderOptions = [
  { label: "ADSTIR", value: "ADSTIR" },
  { label: "ADX", value: "ADX" },
  { label: "VRIZEAD", value: "VRIZEAD" },
  { label: "PROFITX", value: "PROFITX" },
  { label: "GENIEE", value: "GENIEE" },
  { label: "FLUCT", value: "FLUCT" },
  { label: "GMO", value: "GMO" },
  { label: "OPENX", value: "OPENX" },
  { label: "SUPERSHIP", value: "SUPERSHIP" },
  { label: "INMOBI", value: "INMOBI" },
  { label: "BIDSWITCH_GMO", value: "BIDSWITCH_GMO" },
  { label: "BIDSWITCH_GENIEE", value: "BIDSWITCH_GENIEE" },
  { label: "BIDSWITCH_SCALEOUT", value: "BIDSWITCH_SCALEOUT" },
  { label: "BIDSWITCH_MICROAD", value: "BIDSWITCH_MICROAD" },
  { label: "BIDSWITCH_UNRULYX", value: "BIDSWITCH_UNRULYX" },
];

const platformOptions = [
  { value: "", label: "未選択" },
  { value: "SITE", label: "Site" },
  { value: "APP", label: "App" },
];

const osOptions = [
  { value: "", label: "未選択" },
  {
    value: "IOS",
    label: "iOS",
  },
  { value: "ANDROID", label: "Android" },
];

export const AdSlotForm: React.FC<Props> = () => (
  <DialogForm>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormikTextField
          fullWidth
          required
          name="name"
          label="枠名"
          margin="normal"
        />
      </Grid>
      <Grid item xs={12}>
        <FormikTextField
          fullWidth
          required
          name="domain"
          label="ドメイン"
          margin="normal"
        />
      </Grid>
      <Grid item xs={12}>
        <FormikTextField
          fullWidth
          required
          name="tagId"
          label="タグID"
          margin="normal"
        />
      </Grid>
      <Grid item xs={12}>
        <FormikTextField
          fullWidth
          name="bundle"
          label="バンドル"
          margin="normal"
        />
      </Grid>
      <Grid item xs={12}>
        <FormikTextField fullWidth name="note" label="メモ" margin="normal" />
      </Grid>
      <Grid item xs={6}>
        <FormikNumberField
          required
          fullWidth
          name="width"
          label="幅"
          margin="normal"
        />
      </Grid>
      <Grid item xs={6}>
        <FormikNumberField
          required
          fullWidth
          name="height"
          label="高さ"
          margin="normal"
        />
      </Grid>
      <Grid item xs={12}>
        <FormikSelectField
          required
          fullWidth
          name="sspProvider"
          label="プロバイダ"
          options={sspProviderOptions}
        />
      </Grid>
      <Grid item xs={12}>
        <FormikSelectField
          required
          fullWidth
          name="platform"
          label="プラットフォーム"
          options={platformOptions}
        />
      </Grid>
      <Grid item xs={12}>
        <FormikMultipleSelectField
          fullWidth
          name="os"
          label="OS"
          options={osOptions}
        />
      </Grid>
      <Grid item xs={12}>
        <FormikTextField
          fullWidth
          name="adxBillingId"
          label="ADX BILLING ID"
          margin="normal"
        />
      </Grid>
      <Grid item xs={12}>
        <FormikNumberField
          fullWidth
          name="targetWinRate"
          label="目標勝率"
          margin="normal"
          precision={1}
        />
      </Grid>
    </Grid>
  </DialogForm>
);
