/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AdSlotPlatform = "APP" | "SITE" | "%future added value";
export type AdSlotSspProvider = "ADSTIR" | "ADX" | "BIDSWITCH_GENIEE" | "BIDSWITCH_GMO" | "BIDSWITCH_MICROAD" | "BIDSWITCH_SCALEOUT" | "BIDSWITCH_THEMEDIAGRID" | "BIDSWITCH_UNRULYX" | "BIDSWITCH_YIELDONE" | "FLUCT" | "GENIEE" | "GMO" | "INMOBI" | "OPENX" | "PROFITX" | "SUPERSHIP" | "VRIZE" | "VRIZEAD" | "%future added value";
export type Os = "ALL" | "ANDROID" | "IOS" | "UNKNOWN" | "%future added value";
export type AdSlotEditForm_adSlot = {
    readonly id: string;
    readonly name: string | null;
    readonly domain: string;
    readonly tagId: string | null;
    readonly bundle: string | null;
    readonly note: string | null;
    readonly width: number | null;
    readonly height: number | null;
    readonly sspProvider: AdSlotSspProvider;
    readonly platform: AdSlotPlatform | null;
    readonly os: ReadonlyArray<Os> | null;
    readonly adxBillingId: string | null;
    readonly targetWinRate: number;
    readonly " $refType": "AdSlotEditForm_adSlot";
};
export type AdSlotEditForm_adSlot$data = AdSlotEditForm_adSlot;
export type AdSlotEditForm_adSlot$key = {
    readonly " $data"?: AdSlotEditForm_adSlot$data;
    readonly " $fragmentRefs": FragmentRefs<"AdSlotEditForm_adSlot">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdSlotEditForm_adSlot",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "domain",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tagId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bundle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "note",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "width",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "height",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sspProvider",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "platform",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "os",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "adxBillingId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "targetWinRate",
      "storageKey": null
    }
  ],
  "type": "AdSlot",
  "abstractKey": null
};
(node as any).hash = 'e74e421d3364c9679f060c5185978d0b';
export default node;
