import { Button, Grid } from "@material-ui/core";
import {
  FormikNumberField,
  FormikSelectField,
  FormikTextField,
} from "@vrize/vrizead-use";
import { Form, Formik, FormikConfig } from "formik";
import * as React from "react";

import { FilterFormValues } from "./types";

type Props = {
  initialValues: FilterFormValues;
  onSubmit: FormikConfig<FilterFormValues>["onSubmit"];
};

const sspProviderOptions = [
  { label: "ADSTIR", value: "ADSTIR" },
  { label: "ADX", value: "ADX" },
  { label: "VRIZEAD", value: "VRIZEAD" },
  { label: "PROFITX", value: "PROFITX" },
  { label: "GENIEE", value: "GENIEE" },
  { label: "FLUCT", value: "FLUCT" },
  { label: "GMO", value: "GMO" },
  { label: "OPENX", value: "OPENX" },
  { label: "SUPERSHIP", value: "SUPERSHIP" },
  { label: "INMOBI", value: "INMOBI" },
  { label: "BIDSWITCH_GMO", value: "BIDSWITCH_GMO" },
  { label: "BIDSWITCH_GENIEE", value: "BIDSWITCH_GENIEE" },
  { label: "BIDSWITCH_SCALEOUT", value: "BIDSWITCH_SCALEOUT" },
  { label: "BIDSWITCH_MICROAD", value: "BIDSWITCH_MICROAD" },
  { label: "BIDSWITCH_UNRULYX", value: "BIDSWITCH_UNRULYX" },
];

const platformOptions = [
  { value: "", label: "未選択" },
  { value: "SITE", label: "Site" },
  { value: "APP", label: "App" },
];

export const PlacementSettingAdSlotFilterForm: React.FC<Props> = ({
  initialValues,
  onSubmit,
}) => (
  <Formik<FilterFormValues>
    enableReinitialize
    initialValues={initialValues}
    onSubmit={onSubmit}
  >
    <Form>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormikSelectField
            fullWidth
            multiple={false}
            name="sspProvider"
            label="プロバイダ"
            options={sspProviderOptions}
          />
        </Grid>
        <Grid item xs={6}>
          <FormikSelectField
            fullWidth
            multiple={false}
            name="platform"
            label="プラットフォーム"
            options={platformOptions}
          />
        </Grid>
        <Grid item xs={4}>
          <FormikNumberField fullWidth name="width" label="幅" />
        </Grid>
        <Grid item xs={4}>
          <FormikNumberField fullWidth name="height" label="高さ" />
        </Grid>
        <Grid item xs={4}>
          <FormikTextField
            fullWidth
            name="adxBillingId"
            label="ADX BILLING ID"
          />
        </Grid>
        <Button fullWidth type="submit" color="primary" variant="contained">
          適用する
        </Button>
      </Grid>
    </Form>
  </Formik>
);
