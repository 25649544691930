/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AdCategory = "COMIC_BOOKS" | "DRAWING_SKETCHING" | "VIDEO_COMPUTER_GAMES" | "%future added value";
export type AdPlatform = "ALL" | "APP" | "SITE" | "%future added value";
export type AdSspProvider = "ADSTIR" | "ADX" | "ALL" | "BIDSWITCH_GENIEE" | "BIDSWITCH_GMO" | "BIDSWITCH_MICROAD" | "BIDSWITCH_SCALEOUT" | "BIDSWITCH_UNRULYX" | "FLUCT" | "GENIEE" | "GMO" | "INMOBI" | "OPENX" | "PROFITX" | "SUPERSHIP" | "VRIZE" | "VRIZEAD" | "%future added value";
export type AdEditForm_ad = {
    readonly id: string;
    readonly title: string;
    readonly adomain: string | null;
    readonly categories: ReadonlyArray<AdCategory>;
    readonly isRotatable: boolean;
    readonly ignoreIdfa: boolean;
    readonly width: number | null;
    readonly height: number | null;
    readonly sspProvider: AdSspProvider;
    readonly platform: AdPlatform;
    readonly latestVersion: number | null;
    readonly " $refType": "AdEditForm_ad";
};
export type AdEditForm_ad$data = AdEditForm_ad;
export type AdEditForm_ad$key = {
    readonly " $data"?: AdEditForm_ad$data;
    readonly " $fragmentRefs": FragmentRefs<"AdEditForm_ad">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdEditForm_ad",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "adomain",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "categories",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isRotatable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ignoreIdfa",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "width",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "height",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sspProvider",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "platform",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "latestVersion",
      "storageKey": null
    }
  ],
  "type": "Ad",
  "abstractKey": null
};
(node as any).hash = '6e5f7237d43371588801c6cfefeec092';
export default node;
