/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AdSlotPlatform = "APP" | "SITE" | "%future added value";
export type AdSlotSspProvider = "ADSTIR" | "ADX" | "BIDSWITCH_GENIEE" | "BIDSWITCH_GMO" | "BIDSWITCH_MICROAD" | "BIDSWITCH_SCALEOUT" | "BIDSWITCH_THEMEDIAGRID" | "BIDSWITCH_UNRULYX" | "BIDSWITCH_YIELDONE" | "FLUCT" | "GENIEE" | "GMO" | "INMOBI" | "OPENX" | "PROFITX" | "SUPERSHIP" | "VRIZE" | "VRIZEAD" | "%future added value";
export type Os = "ALL" | "ANDROID" | "IOS" | "UNKNOWN" | "%future added value";
export type UpdateAdSlotInput = {
    clientMutationId?: string | null;
    adxBillingId?: string | null;
    bundle?: string | null;
    domain?: string | null;
    height?: number | null;
    id: string;
    name?: string | null;
    note?: string | null;
    platform?: AdSlotPlatform | null;
    os?: Array<Os> | null;
    sspProvider?: AdSlotSspProvider | null;
    tagId?: string | null;
    targetWinRate: number;
    width?: number | null;
    archived?: boolean | null;
};
export type UpdateAdSlotMutationVariables = {
    input: UpdateAdSlotInput;
};
export type UpdateAdSlotMutationResponse = {
    readonly updateAdSlot: {
        readonly adSlot: {
            readonly id: string;
            readonly name: string | null;
            readonly domain: string;
            readonly tagId: string | null;
            readonly sspProvider: AdSlotSspProvider;
            readonly width: number | null;
            readonly height: number | null;
            readonly bundle: string | null;
            readonly platform: AdSlotPlatform | null;
            readonly adxBillingId: string | null;
            readonly archived: boolean;
            readonly " $fragmentRefs": FragmentRefs<"AdSlotEditForm_adSlot">;
        } | null;
    } | null;
};
export type UpdateAdSlotMutation = {
    readonly response: UpdateAdSlotMutationResponse;
    readonly variables: UpdateAdSlotMutationVariables;
};



/*
mutation UpdateAdSlotMutation(
  $input: UpdateAdSlotInput!
) {
  updateAdSlot(input: $input) {
    adSlot {
      id
      name
      domain
      tagId
      sspProvider
      width
      height
      bundle
      platform
      adxBillingId
      archived
      ...AdSlotEditForm_adSlot
    }
  }
}

fragment AdSlotEditForm_adSlot on AdSlot {
  id
  name
  domain
  tagId
  bundle
  note
  width
  height
  sspProvider
  platform
  os
  adxBillingId
  targetWinRate
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "domain",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tagId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sspProvider",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "width",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "height",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bundle",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "platform",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "adxBillingId",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "archived",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateAdSlotMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateAdSlotPayload",
        "kind": "LinkedField",
        "name": "updateAdSlot",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AdSlot",
            "kind": "LinkedField",
            "name": "adSlot",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AdSlotEditForm_adSlot"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateAdSlotMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateAdSlotPayload",
        "kind": "LinkedField",
        "name": "updateAdSlot",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AdSlot",
            "kind": "LinkedField",
            "name": "adSlot",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "note",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "os",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "targetWinRate",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "01ad15c7f7a903209673c6758891e1cc",
    "id": null,
    "metadata": {},
    "name": "UpdateAdSlotMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateAdSlotMutation(\n  $input: UpdateAdSlotInput!\n) {\n  updateAdSlot(input: $input) {\n    adSlot {\n      id\n      name\n      domain\n      tagId\n      sspProvider\n      width\n      height\n      bundle\n      platform\n      adxBillingId\n      archived\n      ...AdSlotEditForm_adSlot\n    }\n  }\n}\n\nfragment AdSlotEditForm_adSlot on AdSlot {\n  id\n  name\n  domain\n  tagId\n  bundle\n  note\n  width\n  height\n  sspProvider\n  platform\n  os\n  adxBillingId\n  targetWinRate\n}\n"
  }
};
})();
(node as any).hash = '6ea27cd54264db386b09a20e5ba3d814';
export default node;
