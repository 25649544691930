/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AdSlotPlatform = "APP" | "SITE" | "%future added value";
export type AdSlotSspProvider = "ADSTIR" | "ADX" | "BIDSWITCH_GENIEE" | "BIDSWITCH_GMO" | "BIDSWITCH_MICROAD" | "BIDSWITCH_SCALEOUT" | "BIDSWITCH_THEMEDIAGRID" | "BIDSWITCH_UNRULYX" | "BIDSWITCH_YIELDONE" | "FLUCT" | "GENIEE" | "GMO" | "INMOBI" | "OPENX" | "PROFITX" | "SUPERSHIP" | "VRIZE" | "VRIZEAD" | "%future added value";
export type PlacementList_ad = {
    readonly id: string;
    readonly latestVersion: number | null;
    readonly placements: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly correctionTerm: number;
                readonly isEnabled: boolean;
                readonly proportion: number;
                readonly latestVersion: number | null;
                readonly adSlot: {
                    readonly id: string;
                    readonly name: string | null;
                    readonly domain: string;
                    readonly tagId: string | null;
                    readonly note: string | null;
                    readonly sspProvider: AdSlotSspProvider;
                    readonly bundle: string | null;
                    readonly platform: AdSlotPlatform | null;
                    readonly width: number | null;
                    readonly height: number | null;
                    readonly adxBillingId: string | null;
                };
                readonly " $fragmentRefs": FragmentRefs<"AdSlotNoteEditForm_placement">;
            } | null;
        } | null> | null;
        readonly totalCount: number;
        readonly pageInfo: {
            readonly hasPreviousPage: boolean;
            readonly hasNextPage: boolean;
            readonly startCursor: string | null;
            readonly endCursor: string | null;
        };
    };
    readonly " $refType": "PlacementList_ad";
};
export type PlacementList_ad$data = PlacementList_ad;
export type PlacementList_ad$key = {
    readonly " $data"?: PlacementList_ad$data;
    readonly " $fragmentRefs": FragmentRefs<"PlacementList_ad">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "latestVersion",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "count"
    },
    {
      "kind": "RootArgument",
      "name": "cursor"
    },
    {
      "kind": "RootArgument",
      "name": "isEnabled"
    },
    {
      "kind": "RootArgument",
      "name": "tagId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "placements"
        ]
      }
    ]
  },
  "name": "PlacementList_ad",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": "placements",
      "args": [
        {
          "kind": "Variable",
          "name": "isEnabled",
          "variableName": "isEnabled"
        },
        {
          "kind": "Variable",
          "name": "tagId",
          "variableName": "tagId"
        }
      ],
      "concreteType": "PlacementConnection",
      "kind": "LinkedField",
      "name": "__PlacementList_placements_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PlacementEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Placement",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "correctionTerm",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isEnabled",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "proportion",
                  "storageKey": null
                },
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AdSlot",
                  "kind": "LinkedField",
                  "name": "adSlot",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "domain",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "tagId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "note",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "sspProvider",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "bundle",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "platform",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "width",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "height",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "adxBillingId",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "AdSlotNoteEditForm_placement"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasPreviousPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Ad",
  "abstractKey": null
};
})();
(node as any).hash = '1ac979af671eaa74f3e899bd368a0bcd';
export default node;
