/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AdSlotPlatform = "APP" | "SITE" | "%future added value";
export type AdSlotSspProvider = "ADSTIR" | "ADX" | "BIDSWITCH_GENIEE" | "BIDSWITCH_GMO" | "BIDSWITCH_MICROAD" | "BIDSWITCH_SCALEOUT" | "BIDSWITCH_THEMEDIAGRID" | "BIDSWITCH_UNRULYX" | "BIDSWITCH_YIELDONE" | "FLUCT" | "GENIEE" | "GMO" | "INMOBI" | "OPENX" | "PROFITX" | "SUPERSHIP" | "VRIZE" | "VRIZEAD" | "%future added value";
export type PlacementCreateButton_QueryVariables = {
    projectId: string;
    adId: string;
    sspProvider: Array<AdSlotSspProvider>;
    platform?: AdSlotPlatform | null;
    width?: number | null;
    height?: number | null;
    adSlotSearch?: string | null;
};
export type PlacementCreateButton_QueryResponse = {
    readonly project: {
        readonly ad: {
            readonly " $fragmentRefs": FragmentRefs<"PlacementCreateButton_ad">;
        } | null;
    } | null;
};
export type PlacementCreateButton_Query = {
    readonly response: PlacementCreateButton_QueryResponse;
    readonly variables: PlacementCreateButton_QueryVariables;
};



/*
query PlacementCreateButton_Query(
  $projectId: ID!
  $adId: ID!
  $sspProvider: [AdSlotSspProvider!]!
  $platform: AdSlotPlatform
  $width: Int
  $height: Int
  $adSlotSearch: String
) {
  project(id: $projectId) {
    ad(id: $adId) {
      ...PlacementCreateButton_ad
      id
    }
    id
  }
}

fragment PlacementCreateButton_ad on Ad {
  selectableAdSlots(first: 150, sspProvider: $sspProvider, platform: $platform, width: $width, height: $height, search: $adSlotSearch) {
    edges {
      node {
        id
        name
        tagId
        platform
        sspProvider
        note
        bundle
        width
        height
        adxBillingId
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "adId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "adSlotSearch"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "height"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "platform"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sspProvider"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "width"
},
v7 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectId"
  }
],
v8 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "adId"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PlacementCreateButton_Query",
    "selections": [
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "Ad",
            "kind": "LinkedField",
            "name": "ad",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "PlacementCreateButton_ad"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v0/*: any*/),
      (v5/*: any*/),
      (v3/*: any*/),
      (v6/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "PlacementCreateButton_Query",
    "selections": [
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "Ad",
            "kind": "LinkedField",
            "name": "ad",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 150
                  },
                  {
                    "kind": "Variable",
                    "name": "height",
                    "variableName": "height"
                  },
                  {
                    "kind": "Variable",
                    "name": "platform",
                    "variableName": "platform"
                  },
                  {
                    "kind": "Variable",
                    "name": "search",
                    "variableName": "adSlotSearch"
                  },
                  {
                    "kind": "Variable",
                    "name": "sspProvider",
                    "variableName": "sspProvider"
                  },
                  {
                    "kind": "Variable",
                    "name": "width",
                    "variableName": "width"
                  }
                ],
                "concreteType": "AdSlotConnection",
                "kind": "LinkedField",
                "name": "selectableAdSlots",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AdSlotEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AdSlot",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "tagId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "platform",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "sspProvider",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "note",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "bundle",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "width",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "height",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "adxBillingId",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "304253e1327cda1a33e80b73e9c60d68",
    "id": null,
    "metadata": {},
    "name": "PlacementCreateButton_Query",
    "operationKind": "query",
    "text": "query PlacementCreateButton_Query(\n  $projectId: ID!\n  $adId: ID!\n  $sspProvider: [AdSlotSspProvider!]!\n  $platform: AdSlotPlatform\n  $width: Int\n  $height: Int\n  $adSlotSearch: String\n) {\n  project(id: $projectId) {\n    ad(id: $adId) {\n      ...PlacementCreateButton_ad\n      id\n    }\n    id\n  }\n}\n\nfragment PlacementCreateButton_ad on Ad {\n  selectableAdSlots(first: 150, sspProvider: $sspProvider, platform: $platform, width: $width, height: $height, search: $adSlotSearch) {\n    edges {\n      node {\n        id\n        name\n        tagId\n        platform\n        sspProvider\n        note\n        bundle\n        width\n        height\n        adxBillingId\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '5a37107ad51bbc1f0adfd94ac8998249';
export default node;
