import { Collapse, Grid, IconButton, Theme } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import {
  FormikNumberField,
  FormikSelectField,
  FormikSwitchField,
  FormikTextField,
} from "@vrize/vrizead-use";
import clsx from "clsx";
import * as React from "react";
import * as yup from "yup";

import { DialogForm } from "./DialogForm";

export type FormValues = {
  title: string;
  adomain: string | null;
  isRotatable: boolean;
  ignoreIdfa: boolean;
  width: number | null;
  height: number | null;
  sspProvider:
    | "ALL"
    | "ADSTIR"
    | "ADX"
    | "VRIZEAD"
    | "PROFITX"
    | "GENIEE"
    | "FLUCT"
    | "GMO"
    | "OPENX"
    | "SUPERSHIP"
    | "INMOBI"
    | "BIDSWITCH_GMO"
    | "BIDSWITCH_GENIEE"
    | "BIDSWITCH_SCALEOUT"
    | "BIDSWITCH_MICROAD";
  platform: "ALL" | "SITE" | "APP";
};

export type Props = {};

export const schema = yup.object({
  title: yup.string().required(),
  adomain: yup.string().nullable(),
  isRotatable: yup.boolean().required(),
  ignoreIdfa: yup.boolean().required(),
  width: yup.number().nullable(),
  height: yup.number().nullable(),
  sspProvider: yup
    .string()
    .oneOf([
      "ALL",
      "ADSTIR",
      "ADX",
      "VRIZEAD",
      "PROFITX",
      "GENIEE",
      "FLUCT",
      "GMO",
      "OPNEX",
      "SUPERSHIP",
      "INMOBI",
      "BIDSWITCH_GMO",
      "BIDSWITCH_GENIEE",
      "BIDSWITCH_SCALEOUT",
      "BIDSWITCH_MICROAD",
      "BIDSWITCH_UNRULYX",
    ]),
  platform: yup.string().oneOf(["ALL", "SITE", "APP"]),
});

const sspProviderOptions = [
  { value: "ALL", label: "All" },
  { label: "ADSTIR", value: "ADSTIR" },
  { label: "ADX", value: "ADX" },
  { label: "VRIZEAD", value: "VRIZEAD" },
  { label: "PROFITX", value: "PROFITX" },
  { label: "GENIEE", value: "GENIEE" },
  { label: "FLUCT", value: "FLUCT" },
  { label: "GMO", value: "GMO" },
  { label: "OPENX", value: "OPENX" },
  { label: "SUPERSHIP", value: "SUPERSHIP" },
  { label: "INMOBI", value: "INMOBI" },
  { label: "BIDSWITCH_GMO", value: "BIDSWITCH_GMO" },
  { label: "BIDSWITCH_GENIEE", value: "BIDSWITCH_GENIEE" },
  { label: "BIDSWITCH_SCALEOUT", value: "BIDSWITCH_SCALEOUT" },
  { label: "BIDSWITCH_MICROAD", value: "BIDSWITCH_MICROAD" },
  { label: "BIDSWITCH_UNRULYX", value: "BIDSWITCH_UNRULYX" },
];

const platformOptions = [
  { value: "ALL", label: "All" },
  { value: "SITE", label: "Site" },
  { value: "APP", label: "App" },
];

const useStyles = makeStyles((theme: Theme) => ({
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

export const AdForm: React.FC<Props> = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const iconClassName = React.useMemo(() => {
    return clsx(classes.expand, {
      [classes.expandOpen]: expanded,
    });
  }, [classes.expand, classes.expandOpen, expanded]);

  const handleExpandClick = React.useCallback(() => {
    setExpanded((s) => !s);
  }, []);

  return (
    <DialogForm>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormikTextField
            fullWidth
            required
            name="title"
            label="広告名"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12}>
          {"詳細設定メニューを開く"}
          <IconButton
            className={iconClassName}
            aria-expanded={expanded}
            aria-label="show more"
            onClick={handleExpandClick}
          >
            <ExpandMore />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormikTextField
                  fullWidth
                  name="adomain"
                  label="ドメイン(未設定の場合はキャンペーンの値が設定されます)"
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <FormikSwitchField
                  name="isRotatable"
                  label="ローテ配信をONにする"
                />
              </Grid>
              <Grid item xs={6}>
                <FormikSwitchField
                  name="ignoreIdfa"
                  label="IDFAを無視して配信する"
                />
              </Grid>
              <Grid item xs={6}>
                <FormikNumberField
                  fullWidth
                  name="width"
                  label="幅"
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <FormikNumberField
                  fullWidth
                  name="height"
                  label="高さ"
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12}>
                <FormikSelectField
                  fullWidth
                  name="sspProvider"
                  label="プロバイダ"
                  options={sspProviderOptions}
                />
              </Grid>
              <Grid item xs={12}>
                <FormikSelectField
                  fullWidth
                  name="platform"
                  label="プラットフォーム"
                  options={platformOptions}
                />
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      </Grid>
    </DialogForm>
  );
};
