/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type AdSlotSspProvider = "ADSTIR" | "ADX" | "BIDSWITCH_GENIEE" | "BIDSWITCH_GMO" | "BIDSWITCH_MICROAD" | "BIDSWITCH_SCALEOUT" | "BIDSWITCH_THEMEDIAGRID" | "BIDSWITCH_UNRULYX" | "BIDSWITCH_YIELDONE" | "FLUCT" | "GENIEE" | "GMO" | "INMOBI" | "OPENX" | "PROFITX" | "SUPERSHIP" | "VRIZE" | "VRIZEAD" | "%future added value";
export type CreatePlacementInput = {
    clientMutationId?: string | null;
    adId: string;
    adSlotId: string;
    proportion?: number | null;
    correctionTerm?: number | null;
};
export type CreatePlacementMutationVariables = {
    input: CreatePlacementInput;
};
export type CreatePlacementMutationResponse = {
    readonly createPlacement: {
        readonly placementEdge: {
            readonly node: {
                readonly id: string;
                readonly correctionTerm: number;
                readonly proportion: number;
                readonly isEnabled: boolean;
                readonly adSlot: {
                    readonly id: string;
                    readonly name: string | null;
                    readonly domain: string;
                    readonly tagId: string | null;
                    readonly sspProvider: AdSlotSspProvider;
                };
                readonly ad: {
                    readonly id: string;
                    readonly title: string;
                    readonly campaign: {
                        readonly id: string;
                        readonly title: string;
                        readonly project: {
                            readonly id: string;
                        };
                    };
                };
            } | null;
        } | null;
    } | null;
};
export type CreatePlacementMutation = {
    readonly response: CreatePlacementMutationResponse;
    readonly variables: CreatePlacementMutationVariables;
};



/*
mutation CreatePlacementMutation(
  $input: CreatePlacementInput!
) {
  createPlacement(input: $input) {
    placementEdge {
      node {
        id
        correctionTerm
        proportion
        isEnabled
        adSlot {
          id
          name
          domain
          tagId
          sspProvider
        }
        ad {
          id
          title
          campaign {
            id
            title
            project {
              id
            }
          }
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreatePlacementPayload",
    "kind": "LinkedField",
    "name": "createPlacement",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PlacementEdge",
        "kind": "LinkedField",
        "name": "placementEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Placement",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "correctionTerm",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "proportion",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AdSlot",
                "kind": "LinkedField",
                "name": "adSlot",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "domain",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "tagId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sspProvider",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Ad",
                "kind": "LinkedField",
                "name": "ad",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Campaign",
                    "kind": "LinkedField",
                    "name": "campaign",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Project",
                        "kind": "LinkedField",
                        "name": "project",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreatePlacementMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreatePlacementMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "69c5d133da847dfd70c711962f76e93d",
    "id": null,
    "metadata": {},
    "name": "CreatePlacementMutation",
    "operationKind": "mutation",
    "text": "mutation CreatePlacementMutation(\n  $input: CreatePlacementInput!\n) {\n  createPlacement(input: $input) {\n    placementEdge {\n      node {\n        id\n        correctionTerm\n        proportion\n        isEnabled\n        adSlot {\n          id\n          name\n          domain\n          tagId\n          sspProvider\n        }\n        ad {\n          id\n          title\n          campaign {\n            id\n            title\n            project {\n              id\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'd842e6bcc3c83705d92e03dc13927e1b';
export default node;
