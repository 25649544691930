/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AdSlotPlatform = "APP" | "SITE" | "%future added value";
export type AdSlotSspProvider = "ADSTIR" | "ADX" | "BIDSWITCH_GENIEE" | "BIDSWITCH_GMO" | "BIDSWITCH_MICROAD" | "BIDSWITCH_SCALEOUT" | "BIDSWITCH_THEMEDIAGRID" | "BIDSWITCH_UNRULYX" | "BIDSWITCH_YIELDONE" | "FLUCT" | "GENIEE" | "GMO" | "INMOBI" | "OPENX" | "PROFITX" | "SUPERSHIP" | "VRIZE" | "VRIZEAD" | "%future added value";
export type BulkCreatePlacementSettingInput = {
    clientMutationId?: string | null;
    placementSetId: string;
    name?: string | null;
    adxBillingId?: string | null;
    sspProvider?: AdSlotSspProvider | null;
    width?: number | null;
    height?: number | null;
    platform?: AdSlotPlatform | null;
};
export type BulkCreatePlacementSettingMutationVariables = {
    input: BulkCreatePlacementSettingInput;
    first?: number | null;
    after?: string | null;
};
export type BulkCreatePlacementSettingMutationResponse = {
    readonly bulkCreatePlacementSetting: {
        readonly placementSet: {
            readonly id: string;
            readonly " $fragmentRefs": FragmentRefs<"PlacementSettingListTableCard_placementSet">;
        } | null;
    } | null;
};
export type BulkCreatePlacementSettingMutation = {
    readonly response: BulkCreatePlacementSettingMutationResponse;
    readonly variables: BulkCreatePlacementSettingMutationVariables;
};



/*
mutation BulkCreatePlacementSettingMutation(
  $input: BulkCreatePlacementSettingInput!
  $first: Int
  $after: String
) {
  bulkCreatePlacementSetting(input: $input) {
    placementSet {
      id
      ...PlacementSettingListTableCard_placementSet
    }
  }
}

fragment PlacementSettingListTableCard_placementSet on PlacementSet {
  id
  placementSettings(first: $first, after: $after) {
    edges {
      node {
        id
        adSlot {
          id
          name
          domain
          tagId
          bundle
          note
          width
          height
          sspProvider
          platform
          bannerFormat
          deletedAt
          adxBillingId
          targetWinRate
        }
        __typename
      }
      cursor
    }
    totalCount
    pageInfo {
      hasPreviousPage
      hasNextPage
      startCursor
      endCursor
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v3 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BulkCreatePlacementSettingMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "BulkCreatePlacementSettingPayload",
        "kind": "LinkedField",
        "name": "bulkCreatePlacementSetting",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PlacementSet",
            "kind": "LinkedField",
            "name": "placementSet",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "PlacementSettingListTableCard_placementSet"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "BulkCreatePlacementSettingMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "BulkCreatePlacementSettingPayload",
        "kind": "LinkedField",
        "name": "bulkCreatePlacementSetting",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PlacementSet",
            "kind": "LinkedField",
            "name": "placementSet",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "PlacementSettingConnection",
                "kind": "LinkedField",
                "name": "placementSettings",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PlacementSettingEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PlacementSetting",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AdSlot",
                            "kind": "LinkedField",
                            "name": "adSlot",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "domain",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "tagId",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "bundle",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "note",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "width",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "height",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "sspProvider",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "platform",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "bannerFormat",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "deletedAt",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "adxBillingId",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "targetWinRate",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v5/*: any*/),
                "filters": [],
                "handle": "connection",
                "key": "PlacementSettingListTableCard_placementSettings",
                "kind": "LinkedHandle",
                "name": "placementSettings"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f25f8b77b64ea4fd2bd12a37c63b0e70",
    "id": null,
    "metadata": {},
    "name": "BulkCreatePlacementSettingMutation",
    "operationKind": "mutation",
    "text": "mutation BulkCreatePlacementSettingMutation(\n  $input: BulkCreatePlacementSettingInput!\n  $first: Int\n  $after: String\n) {\n  bulkCreatePlacementSetting(input: $input) {\n    placementSet {\n      id\n      ...PlacementSettingListTableCard_placementSet\n    }\n  }\n}\n\nfragment PlacementSettingListTableCard_placementSet on PlacementSet {\n  id\n  placementSettings(first: $first, after: $after) {\n    edges {\n      node {\n        id\n        adSlot {\n          id\n          name\n          domain\n          tagId\n          bundle\n          note\n          width\n          height\n          sspProvider\n          platform\n          bannerFormat\n          deletedAt\n          adxBillingId\n          targetWinRate\n        }\n        __typename\n      }\n      cursor\n    }\n    totalCount\n    pageInfo {\n      hasPreviousPage\n      hasNextPage\n      startCursor\n      endCursor\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '5c29fd38aaf7620ad7f8d073e078eae5';
export default node;
