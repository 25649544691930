/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AdCategory = "COMIC_BOOKS" | "DRAWING_SKETCHING" | "VIDEO_COMPUTER_GAMES" | "%future added value";
export type AdPlatform = "ALL" | "APP" | "SITE" | "%future added value";
export type AdSspProvider = "ADSTIR" | "ADX" | "ALL" | "BIDSWITCH_GENIEE" | "BIDSWITCH_GMO" | "BIDSWITCH_MICROAD" | "BIDSWITCH_SCALEOUT" | "BIDSWITCH_UNRULYX" | "FLUCT" | "GENIEE" | "GMO" | "INMOBI" | "OPENX" | "PROFITX" | "SUPERSHIP" | "VRIZE" | "VRIZEAD" | "%future added value";
export type CampaignStatus = "ACTIVE" | "PENDING" | "STOPPED" | "%future added value";
export type UpdateAdInput = {
    clientMutationId?: string | null;
    category?: Array<AdCategory> | null;
    clientVersion?: number | null;
    id: string;
    isRotatable: boolean;
    adomain?: string | null;
    ignoreIdfa: boolean;
    title: string;
    platform?: AdPlatform | null;
    sspProvider?: AdSspProvider | null;
    width?: number | null;
    height?: number | null;
};
export type UpdateAdMutationVariables = {
    input: UpdateAdInput;
};
export type UpdateAdMutationResponse = {
    readonly updateAd: {
        readonly ad: {
            readonly id: string;
            readonly title: string;
            readonly adomain: string | null;
            readonly status: CampaignStatus;
            readonly createdAt: string;
            readonly latestVersion: number | null;
            readonly placements: {
                readonly totalCount: number;
            };
            readonly " $fragmentRefs": FragmentRefs<"AdEditForm_ad">;
        } | null;
    } | null;
};
export type UpdateAdMutation = {
    readonly response: UpdateAdMutationResponse;
    readonly variables: UpdateAdMutationVariables;
};



/*
mutation UpdateAdMutation(
  $input: UpdateAdInput!
) {
  updateAd(input: $input) {
    ad {
      id
      title
      adomain
      status
      createdAt
      latestVersion
      placements {
        totalCount
      }
      ...AdEditForm_ad
    }
  }
}

fragment AdEditForm_ad on Ad {
  id
  title
  adomain
  categories
  isRotatable
  ignoreIdfa
  width
  height
  sspProvider
  platform
  latestVersion
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "adomain",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "latestVersion",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "PlacementConnection",
  "kind": "LinkedField",
  "name": "placements",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateAdMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateAdPayload",
        "kind": "LinkedField",
        "name": "updateAd",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Ad",
            "kind": "LinkedField",
            "name": "ad",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AdEditForm_ad"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateAdMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateAdPayload",
        "kind": "LinkedField",
        "name": "updateAd",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Ad",
            "kind": "LinkedField",
            "name": "ad",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "categories",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isRotatable",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ignoreIdfa",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "width",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "height",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sspProvider",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "platform",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6bab2d05317eb2cea2f55be2c3e7f659",
    "id": null,
    "metadata": {},
    "name": "UpdateAdMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateAdMutation(\n  $input: UpdateAdInput!\n) {\n  updateAd(input: $input) {\n    ad {\n      id\n      title\n      adomain\n      status\n      createdAt\n      latestVersion\n      placements {\n        totalCount\n      }\n      ...AdEditForm_ad\n    }\n  }\n}\n\nfragment AdEditForm_ad on Ad {\n  id\n  title\n  adomain\n  categories\n  isRotatable\n  ignoreIdfa\n  width\n  height\n  sspProvider\n  platform\n  latestVersion\n}\n"
  }
};
})();
(node as any).hash = 'f1f846861a922d7dbb75ca1d971f928a';
export default node;
