/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type CreativeBulkAllocationForm_QueryVariables = {
    projectId: string;
    adIds: Array<string>;
    creativeTitle?: string | null;
};
export type CreativeBulkAllocationForm_QueryResponse = {
    readonly project: {
        readonly ads: ReadonlyArray<{
            readonly id: string;
            readonly creatives: {
                readonly edges: ReadonlyArray<{
                    readonly node: {
                        readonly id: string;
                        readonly title: string;
                        readonly adm: string | null;
                        readonly height: number | null;
                        readonly width: number | null;
                        readonly createdAt: string;
                    } | null;
                } | null> | null;
            };
            readonly selectableCreatives: {
                readonly edges: ReadonlyArray<{
                    readonly node: {
                        readonly id: string;
                        readonly title: string;
                        readonly adm: string | null;
                        readonly height: number | null;
                        readonly width: number | null;
                        readonly createdAt: string;
                    } | null;
                } | null> | null;
            };
        }> | null;
    } | null;
};
export type CreativeBulkAllocationForm_Query = {
    readonly response: CreativeBulkAllocationForm_QueryResponse;
    readonly variables: CreativeBulkAllocationForm_QueryVariables;
};



/*
query CreativeBulkAllocationForm_Query(
  $projectId: ID!
  $adIds: [ID!]!
  $creativeTitle: String
) {
  project(id: $projectId) {
    ads(ids: $adIds) {
      id
      creatives(first: 100) {
        edges {
          node {
            id
            title
            adm
            height
            width
            createdAt
            __typename
          }
          cursor
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
      selectableCreatives(first: 100, search: $creativeTitle) {
        edges {
          node {
            id
            title
            adm
            height
            width
            createdAt
            __typename
          }
          cursor
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "adIds"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "creativeTitle"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "projectId"
  }
],
v4 = [
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "adIds"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CreativeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Creative",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "adm",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "height",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "width",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v7 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "creativeTitle"
},
v8 = {
  "kind": "Literal",
  "name": "first",
  "value": 100
},
v9 = [
  (v8/*: any*/)
],
v10 = [
  (v8/*: any*/),
  (v7/*: any*/)
],
v11 = {
  "count": null,
  "cursor": null,
  "direction": "forward",
  "path": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CreativeBulkAllocationForm_Query",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "Ad",
            "kind": "LinkedField",
            "name": "ads",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              {
                "alias": "creatives",
                "args": null,
                "concreteType": "CreativeConnection",
                "kind": "LinkedField",
                "name": "__CreateAdCreativeMutation_creatives_connection",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": null
              },
              {
                "alias": "selectableCreatives",
                "args": [
                  (v7/*: any*/)
                ],
                "concreteType": "CreativeConnection",
                "kind": "LinkedField",
                "name": "__DeleteAdCreativeMutation_selectableCreatives_connection",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "CreativeBulkAllocationForm_Query",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "project",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "Ad",
            "kind": "LinkedField",
            "name": "ads",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": (v9/*: any*/),
                "concreteType": "CreativeConnection",
                "kind": "LinkedField",
                "name": "creatives",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": "creatives(first:100)"
              },
              {
                "alias": null,
                "args": (v9/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "CreateAdCreativeMutation_creatives",
                "kind": "LinkedHandle",
                "name": "creatives"
              },
              {
                "alias": null,
                "args": (v10/*: any*/),
                "concreteType": "CreativeConnection",
                "kind": "LinkedField",
                "name": "selectableCreatives",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v10/*: any*/),
                "filters": [
                  "search"
                ],
                "handle": "connection",
                "key": "DeleteAdCreativeMutation_selectableCreatives",
                "kind": "LinkedHandle",
                "name": "selectableCreatives"
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "73d8a4441efa130876e4e566c0f5412c",
    "id": null,
    "metadata": {
      "connection": [
        (v11/*: any*/),
        (v11/*: any*/)
      ]
    },
    "name": "CreativeBulkAllocationForm_Query",
    "operationKind": "query",
    "text": "query CreativeBulkAllocationForm_Query(\n  $projectId: ID!\n  $adIds: [ID!]!\n  $creativeTitle: String\n) {\n  project(id: $projectId) {\n    ads(ids: $adIds) {\n      id\n      creatives(first: 100) {\n        edges {\n          node {\n            id\n            title\n            adm\n            height\n            width\n            createdAt\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n      selectableCreatives(first: 100, search: $creativeTitle) {\n        edges {\n          node {\n            id\n            title\n            adm\n            height\n            width\n            createdAt\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '7641fae3e5eb93b44e8f354944b5bb7d';
export default node;
